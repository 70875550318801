import "./style.scss";

import {

  Typography

} from "@material-ui/core";
// import img
import ProConnectImg from "../../assets/ProSanteConnect.png";

const Login = () => {
  document.title = "Login Page";
  let URL = process.env.REACT_APP_API_URL;
  let linkPreProd = URL + "/v1/auth";

  const openReactNativeApp = () => {
    // Remplace 'monapp://' par le schéma de lien personnalisé de ton application React Native
    const appLink = 'cwe://';

    // Ouvre le lien personnalisé de l'application React Native
    window.location.href = appLink;
  };

  return (
    
    <div className="center">
      <Typography variant="h4" color="primary" className="login" >
       Connexion avec<br></br>votre carte eCPS
      </Typography>
      <a href={linkPreProd}>
        <img
          className="image"
          src={ProConnectImg}
          alt="Logo Pro Santé Connect"
        />
      </a>
      {/* <button onClick={openReactNativeApp}>
      Lien vers mon application React Native
    </button> */}
    </div>

  );
};

export default Login;
